import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <svg height="96px" width="270px">
          <defs>
            <linearGradient id="logo-gradient" x1="0%" x2="70.711%" y1="0%" y2="70.711%">
              <stop class="stop_1" offset="0%" stop-color="rgb(160,13,249)" stop-opacity="1" />
              <stop class="stop_2" offset="100%" stop-color="rgb(227,0,90)" stop-opacity="1" />
            </linearGradient>
          </defs>
          <g>
            <polygon class="st0" points="31.2,96 0,96 48,0 78,0 " fill="url('#logo-gradient')" />
            <polygon class="st0" points="175.2,96 144,96 192,0 222,0 " fill="url('#logo-gradient')" />
            <polygon class="st0" points="223.2,96 192,96 240,0 270,0 " fill="url('#logo-gradient')" />
            <polygon class="st1" points="126,96 96,96 48,0 78,0 " fill="#111111" />
            <polygon class="st1" points="174,96 144,96 96,0 126,0 " fill="#111111" />
            <polygon class="st1" points="222,96 192,96 144,0 174,0 " fill="#111111" />
          </g>
        </svg>
        <p>
          Alexander Weingart<br />Software Engineer
        </p>
      </header>
    </div>
  );
}

export default App;
